// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GameFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M31.97 16.18c-.3-6.37-2.85-10.62-6.8-11.36a51 51 0 0 0-18.35 0c-3.95.74-6.49 5-6.8 11.38-.17 3.74.56 9.6 3.31 10.38q.43.12.86.12c1.47 0 3-1.05 4.68-3.19a7.3 7.3 0 0 1 5.77-2.88h2.71a7.3 7.3 0 0 1 5.77 2.88c2 2.56 3.82 3.56 5.54 3.07 2.76-.78 3.5-6.65 3.31-10.4M11.01 13.5H9.54v1.44c0 .6-.5 1.09-1.1 1.09a1.1 1.1 0 0 1-1.12-1.1v-1.43H5.86a1.1 1.1 0 0 1-1.11-1.1c0-.6.5-1.08 1.1-1.08h1.47V9.89c0-.6.5-1.09 1.11-1.09s1.11.49 1.11 1.1v1.43h1.47c.61 0 1.1.48 1.1 1.09 0 .6-.49 1.09-1.1 1.09m12.64 1.52q-.09.22-.25.37c-.22.22-.53.35-.84.35q-.47-.01-.83-.35-.16-.15-.25-.37a1 1 0 0 1-.1-.44q0-.12.03-.23l.07-.2.1-.21.15-.18a1.25 1.25 0 0 1 1.67 0l.14.18q.08.09.1.2l.08.21.02.23q0 .23-.1.44m1.9-4.4q-.1.21-.25.37-.36.34-.84.35c-.3 0-.61-.13-.84-.35q-.16-.15-.25-.37a1 1 0 0 1-.09-.44l.02-.23.07-.2a1 1 0 0 1 .11-.21l.14-.18a1.25 1.25 0 0 1 1.68 0l.14.18q.07.09.1.2.06.1.07.21.03.12.03.23 0 .23-.1.44',
        },
      },
    ],
  },
  name: 'game',
  theme: 'filled',
};

export default GameFilled;
