// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GiftOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { mask: 'url(#mask0_2032_3052)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M2.74 14.33V7.08H1.67V3.6H5.1a1 1 0 0 1-.19-.36 2 2 0 0 1-.05-.43q0-.75.53-1.27a1.7 1.7 0 0 1 1.98-.37q.32.16.58.43.26-.28.59-.43.32-.16.7-.16.75 0 1.28.53A1.7 1.7 0 0 1 11 3.23q-.05.2-.2.37h3.53v3.48h-1.07v7.25zM9.26 2.01a.8.8 0 0 0-.57.23.8.8 0 0 0-.23.57q0 .33.23.56.22.23.57.23.33 0 .56-.23a.8.8 0 0 0 .23-.56.8.8 0 0 0-.23-.57.8.8 0 0 0-.56-.23m-3.39.8q0 .33.23.56t.57.23.56-.23a.8.8 0 0 0 .23-.56.8.8 0 0 0-.23-.57.8.8 0 0 0-.56-.23.8.8 0 0 0-.57.23.8.8 0 0 0-.23.57m-3.2 1.8v1.47H7.5V4.6zm4.83 8.72V7.08H3.74v6.25zm1 0h3.76V7.08H8.5zm4.83-7.25V4.6H8.5v1.48z',
            },
          },
        ],
      },
    ],
  },
  name: 'gift',
  theme: 'outlined',
};

export default GiftOutlined;
