// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AnnounceFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M29.55 11.68c-1.17-6.02-8.04-12.71-12.9-6.64L2.94 15.4c-.03.02-2.03 1.87-.1 4.78.14.4 1.37 1.44 3.44 1.55 4.83.65 12.64 1.8 17.54 2.45 4.98-.18 7.2-6.19 5.73-12.51m-5.02 10.39c-4.1.78-6.97-3.67-7.83-7.23-.88-3.65-.39-8.81 3.6-10.04 7.68-1.43 11.7 14.98 4.23 17.27',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M17.69 10.24a11.7 11.7 0 0 0 2 8.2 5.2 5.2 0 0 0 2.54-3.38c.6-3.06-1.9-5.15-4.54-4.82M12.3 26.4a3.9 3.9 0 0 1-3.33-1.35c-.45-.59-.7-1.3-.74-2.03-.16-.02-1.98-.27-2.09-.3l1.65 5.56a1.5 1.5 0 0 0 1.85 1.02l1.79-.5a1.5 1.5 0 0 0 1.02-1.92z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M12.06 23.56c-.83-.13-1.98-.27-2.8-.4.1 1.34 1.31 2.27 2.75 2.27a.62.62 0 0 0 .53-.97q-.3-.42-.48-.9',
        },
      },
    ],
  },
  name: 'announce',
  theme: 'filled',
};

export default AnnounceFilled;
