// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { BetTransactionFilled as BetTransactionFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const BetTransactionFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={BetTransactionFilledSvg} />
);

BetTransactionFilled.displayName = 'BetTransactionFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  BetTransactionFilled,
);
