// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CustomerServiceFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 31 31',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2022_7060)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'm30.67 21.24-2.93-2.94-6.08 6.08a7.4 7.4 0 0 1-3.92 2.05l3.87 3.88a.9.9 0 0 0 1.28 0l7.78-7.79a.9.9 0 0 0 0-1.28M26.54 16.93a2.62 2.62 0 0 0-.87-4.3 2.63 2.63 0 0 0-1.46-3.4 2.62 2.62 0 0 0-1.46-3.4A2.63 2.63 0 0 0 18.45 3l-1.73 1.72.88.88a3.77 3.77 0 0 1-1.76 6.32 3.7 3.7 0 0 1-2.75 2.75 3.7 3.7 0 0 1-2.75 2.75 3.74 3.74 0 0 1-3.66 2.86c-1 0-1.95-.39-2.66-1.1l-.88-.88-2.93 2.94a.9.9 0 0 0 0 1.28l7.78 7.79a.9.9 0 0 0 1.28 0l5.57-5.57h1.57c1.5 0 2.9-.59 3.97-1.65z',
            },
          },
          {
            tag: 'path',
            attrs: {
              d: 'M5.3 17.9a1.95 1.95 0 1 0 2.76-2.75 1.95 1.95 0 1 0 2.75-2.76 1.95 1.95 0 0 0 2.76-2.75 1.95 1.95 0 0 0 2.75-2.75L12.44 3a1.95 1.95 0 0 0-2.75 2.76l.48.48A1.95 1.95 0 1 0 7.42 9l.97.97a1.95 1.95 0 0 0-2.75 2.75l.97.98a1.95 1.95 0 1 0-2.75 2.75zM17.16 1.71l.43-.37-.54-.53a.9.9 0 0 0-1.04-.17l-2.25 1.12 1.68 1.68z',
            },
          },
        ],
      },
    ],
  },
  name: 'customer-service',
  theme: 'filled',
};

export default CustomerServiceFilled;
