export enum LanguageEnum {
  TH = 'th',
  EN = 'en',
  VI = 'vi',
}

export enum RegionEnum {
  US = 'us',
  VN = 'vn',
  TH = 'th',
}

export const MoneyFormat = '0,0.00';
export enum CurrencyEnum {
  THB = 'THB',
  VND = 'VND',
}

export const S3_BUCKET_IMAGES = `${process.env.NEXT_PUBLIC_S3_BUCKET_ASSETS_URL}/agents/new88/images`;

export const DEFAULT_LANG = LanguageEnum.VI;

export const DEFAULT_REGION_CODE = RegionEnum.VN;

export const DEFAULT_CURRENCY = CurrencyEnum.THB;

export const FALLBACK_LANG = LanguageEnum.VI;

export const NEXT_LOCALE = 'NEXT_LOCALE';

export const ITEMS_PER_PAGE = 20;

export const TOTAL_ITEMS = 100;

export enum FinancialConfigKeys {
  RequireVerifyAccount = 'require-verified-user-before-withdrawal',
}

export enum OtpKeyEnum {
  OtpUpdateProfile = 'otp-update-profile',
  OtpResetPassword = 'otp-reset-password',
  OtpRegisterForm = 'otp-register-form',
}

export enum NoteColor {
  Default = 'default',
  Success = 'success',
  Danger = 'danger',
  Ghost = 'ghost',
}

export enum WalletEnum {
  CREDIT = 'credit',
  BONUS = 'bonus',
  REVENUE = 'revenue',
}

export enum PaymentMethodProviderEnum {
  BankTransfer = 'bank-transfer',
  OnlinePayment = 'online-payment',
}

export enum StatusEnum {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Finished = 'finished',
  InProgress = 'in-progress',
  Success = 'success',
  Waiting = 'waiting',
  Pending = 'pending',
  Active = 'active',
  Disabled = 'disabled',
  Claimed = 'claimed',
  Redeemed = 'redeemed',
}

export enum TransactionTypeEnum {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export enum ContactTypeEnum {
  Telegram = 'Telegram',
  Facebook = 'Facebook',
  FacebookPage = 'Facebook Page',
  FacebookGroup = 'Facebook Group',
  LineOfficial = 'Line Official Account',
  Line = 'Line',
  Youtube = 'Youtube',
  Discord = 'Discord',
}

export enum ProviderTypeEnum {
  Game = 'game',
  Casino = 'casino',
  Gaming = 'gaming',
  Sportsbook = 'sportsbook',
  Lotto = 'lotto',
}

export enum WithdrawConditionTypeEnum {
  Turnover = 'TURNOVER',
  Bill = 'BILL',
  Profit = 'PROFIT',
}

export enum PurchasesTypeEnum {
  Renewable = 'renewable',
  Ongoing = 'ongoing',
  Limited = 'limited',
}

export const MoneyWithDecimalFormat = '0,0.[00]';

export const VND1000Format = '0,0.000';

export const THBFormat = MoneyWithDecimalFormat;

export enum EnumActionNotification {
  DepositSuccess = 'deposit-finished',
  DepositCancelled = 'deposit-cancelled',
  WithdrawalSuccess = 'withdrawal-finished',
  WithdrawalCancelled = 'withdrawal-cancelled',
}

export enum LottoRedirectPathEnum {
  BetTransaction = 'bet-transactions',
  LottoPrize = 'lotto-prize',
}

export enum EnumAffiliatePublicConfigKeys {
  BetCommissionRate = 'BET_COMMISSION_SINGLE_RATE',
}

export enum AffiliateStatsTypeEnum {
  Commission = 'commission',
  Financial = 'financial',
  MemberBetting = 'member-betting',
  MemberInteraction = 'member-interaction',
  Member = 'member',
}

export enum WalletWidgetTypeEnum {
  Navigation = 'navigation',
  Financial = 'financial',
}
