// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SlotFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2022_6944)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M14.74 17.7a5.5 5.5 0 0 0-6.24-.62 5.4 5.4 0 0 0-2.68-.7c-3.17 0-5.76 2.77-5.76 6.18C.06 27.68 3.93 32 8.5 32c2.5 0 4.8-1.3 6.37-3.32a12 12 0 0 1-1.68-6.12c0-1.83.57-3.51 1.55-4.86M19.16 13.76q.23.44.47.84a7 7 0 0 1 2.19-.03q-.52-.78-1.04-1.75A19 19 0 0 1 18.61 6q.26.35.6.68c2.19 2.2 5.58 2.26 5.73 2.27a.94.94 0 0 0 .94-.95c0-.15-.06-3.54-2.26-5.74C21.43.07 18.05 0 17.89 0h-.06a1 1 0 0 0-.4.12 16.6 16.6 0 0 0-5.76 5.08 21 21 0 0 0-2.91 5.96c-.42 1.33-.7 2.58-.87 3.64l.61.21q.64-.26 1.32-.38.25-1.34.73-2.9 1.87-5.92 6.14-8.97c-.27 3.68.57 7.45 2.47 11M26.18 16.38q-1.43 0-2.68.7a5.4 5.4 0 0 0-2.68-.7c-3.17 0-5.76 2.77-5.76 6.18 0 5.12 3.87 9.44 8.44 9.44s8.44-4.32 8.44-9.44c0-3.41-2.59-6.18-5.76-6.18',
            },
          },
        ],
      },
    ],
  },
  name: 'slot',
  theme: 'filled',
};

export default SlotFilled;
