// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BankOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M5.75 17V9.5h1.5V17zm5.5 0V9.5h1.5V17zM2.77 7.5V6.08L12 1.56l9.23 4.52V7.5zM6.33 6h11.34L12 3.25zM2.77 20.5V19h11.58a10 10 0 0 0 .24 1.5zm13.98-6.74V9.5h1.5v3.51zM20 23.62a4.6 4.6 0 0 1-2.65-1.84 5.3 5.3 0 0 1-1.04-3.2v-2.35L20 14.38l3.7 1.85v2.36a5.3 5.3 0 0 1-1.05 3.2A4.6 4.6 0 0 1 20 23.61m-.73-2.83 3.26-3.24-.84-.84-2.42 2.39-.97-.98-.84.86z',
        },
      },
    ],
  },
  name: 'bank',
  theme: 'outlined',
};

export default BankOutlined;
