// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HomeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M31.65 16.15q-.46.73-1.23 1.14-.8.4-1.69.37h-.4v8.47c0 .8-.34 1.57-.94 2.13s-1.41.88-2.27.88h-2.7c-.86 0-1.67-.32-2.27-.88a3 3 0 0 1-.94-2.13v-2.87c0-.8-.34-1.56-.94-2.12a3.3 3.3 0 0 0-2.27-.88c-.85 0-1.66.31-2.27.88-.6.56-.94 1.33-.94 2.12v2.9c0 .8-.33 1.57-.93 2.13s-1.42.88-2.27.88H6.88c-.85 0-1.67-.32-2.27-.88a3 3 0 0 1-.94-2.13V17.7h-.4q-.9.03-1.68-.37a3 3 0 0 1-1.24-1.14 2.84 2.84 0 0 1 .66-3.59L13.84 1.6a3.34 3.34 0 0 1 4.18 0l12.91 11.02q.8.66 1.01 1.65c.14.64.03 1.3-.29 1.88',
        },
      },
    ],
  },
  name: 'home',
  theme: 'filled',
};

export default HomeFilled;
