// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PromotionFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M27.67 8.33H4.33A2.67 2.67 0 0 0 1.67 11v3.33c0 1.48 1.2 2.67 2.66 2.67h23.34c1.47 0 2.66-1.2 2.66-2.67V11c0-1.47-1.2-2.67-2.66-2.67M15 18.33v12H7.33a3.66 3.66 0 0 1-3.66-3.66v-8.63q.8.3 1.66.3zM28.33 18.04v8.63a3.66 3.66 0 0 1-3.66 3.66H17v-12h9.67q.85 0 1.66-.29',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M20.67 10.33H16a1 1 0 0 1-.98-1.2l.68-3.4a5.1 5.1 0 0 1 4.97-4.06 4.33 4.33 0 1 1 0 8.66m-3.45-2h3.45a2.33 2.33 0 0 0 0-4.66 3.1 3.1 0 0 0-3.01 2.46z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M16 10.33h-4.67a4.33 4.33 0 0 1 0-8.66 5.1 5.1 0 0 1 4.97 4.07l.68 3.4a1 1 0 0 1-.98 1.2m-4.67-6.66a2.33 2.33 0 0 0 0 4.66h3.45l-.44-2.2a3.1 3.1 0 0 0-3-2.46',
        },
      },
    ],
  },
  name: 'promotion',
  theme: 'filled',
};

export default PromotionFilled;
