// This icon file is generated automatically.

import { IconDefinition } from '../types';

const Responsibly18Filled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2051_10321)' },
        children: [
          {
            tag: 'mask',
            attrs: {
              height: '28',
              id: 'a',
              maskUnits: 'userSpaceOnUse',
              style: 'mask-type:luminance',
              width: '28',
              x: '1',
              y: '1',
            },
            children: [
              { tag: 'path', attrs: { d: 'M1.5 1.5h26.79v26.79H1.5z' } },
            ],
          },
          {
            tag: 'g',
            attrs: { mask: 'url(#a)' },
            children: [
              {
                tag: 'path',
                attrs: {
                  'clip-rule': 'evenodd',
                  d: 'M18.43 1.99v2.45c-4.03-1.17-7.7-.52-10.86 2.26a10.5 10.5 0 0 0-3.67 7.92 10.97 10.97 0 0 0 11.82 11.23 10.6 10.6 0 0 0 8.33-4.89c1.98-2.96 2.3-6.19 1.3-9.56l.02-.04.02-.01 2.17-.02.24.01q.8 2.85.3 5.78a13 13 0 0 1-4.48 7.9 13.3 13.3 0 0 1-17.28.16A13.3 13.3 0 0 1 3.17 8.45c3.3-5.93 9.9-8 15.26-6.46',
                  'fill-rule': 'evenodd',
                },
              },
            ],
          },
          {
            tag: 'path',
            attrs: {
              'clip-rule': 'evenodd',
              d: 'M19.42 10.24c.64.18 1.21.5 1.6 1.09.6.96.49 2.32-.58 3.1l-.17.12.34.2a2.5 2.5 0 0 1 1.25 2.3 2.4 2.4 0 0 1-1.12 1.98q-.7.45-1.5.58-1.35.25-2.7-.1-.69-.18-1.27-.63a2.46 2.46 0 0 1-.32-3.52q.45-.51 1.09-.8l-.41-.34a2.22 2.22 0 0 1 .32-3.63q.7-.38 1.48-.47a5 5 0 0 1 1.99.12m-2 5.38c-.52.14-.83.54-.84 1.07-.02.56.26.98.8 1.14q.28.08.57.1.2.03.4.01.33-.03.63-.15c.79-.3.8-1.33.45-1.78q-.27-.3-.65-.4a2.6 2.6 0 0 0-1.36 0m.27-3.9q-.88.18-.86 1.08 0 .65.6.86l.07.02a2.3 2.3 0 0 0 1.28-.01.8.8 0 0 0 .54-.49c.22-.54.05-1.28-.72-1.45a2 2 0 0 0-.91-.01m6.75-9.15q.01 1.37.01 2.73h2.72c.06.3.06 1.76 0 2h-2.71c-.03.93 0 1.83-.02 2.76q-.98.01-1.97.01c-.05-.32-.02-.62-.03-.92V7.32h-2.66a9 9 0 0 1-.02-2.01h2.68V2.57zM9.72 19.66v-7.61H7.98a10 10 0 0 1 0-1.98h4.19c.05.22.06 9.33.01 9.6-.25.06-2.2.06-2.46-.01',
              'fill-rule': 'evenodd',
            },
          },
        ],
      },
    ],
  },
  name: 'responsibly-18',
  theme: 'filled',
};

export default Responsibly18Filled;
