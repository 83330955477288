// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AffiliateFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2022_6951)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M18.09 22.84a3.5 3.5 0 0 0 1.4-2.8c-.17-4.63-6.84-4.63-7 0 0 1.14.54 2.16 1.4 2.8a5.3 5.3 0 0 0-3.17 4.82v3c0 .6.49 1.09 1.1 1.09h8.33c.6 0 1.1-.5 1.1-1.1v-2.99c0-2.15-1.3-4.01-3.16-4.82',
            },
          },
          {
            tag: 'path',
            attrs: {
              d: 'M27.02 11.28q-.32 0-.62.04a11.5 11.5 0 0 0-5.48-5.4A5 5 0 0 0 15.98.25a5 5 0 0 0-4.93 5.67 11.5 11.5 0 0 0-5.48 5.4l-.62-.04c-6.6.27-6.61 9.71 0 9.98l.48-.03q.91 2 2.48 3.53h-.65c-.74 0-.76 1.13 0 1.15h2.18c.36.05.7-.34.6-.68v-2.1c-.01-.76-1.13-.75-1.14 0v.98a10 10 0 0 1-2.33-3.13c4.38-1.5 4.47-7.75.13-9.37a10.4 10.4 0 0 1 4.64-4.56 5 5 0 0 0 9.28 0c2 .98 3.61 2.57 4.64 4.56-4.34 1.62-4.25 7.87.13 9.37a11 11 0 0 1-2.34 3.15v-1c0-.74-1.14-.74-1.14 0v2.21q.02.34.33.51c.08.13 2.28.03 2.44.06.75 0 .76-1.14 0-1.14h-.64q1.58-1.52 2.49-3.54a5 5 0 1 0 .5-9.95M4.76 15.61l.75.24c1.37.42 1.33 2.45.01 2.9.04.81-1.17.81-1.14 0-.76-.18-1.61-1.87-.43-2.04.31 0 .57.26.57.57 0 .24.2.44.43.44.43.02.63-.64.2-.78l-.75-.25c-1.37-.43-1.32-2.44-.02-2.9-.04-.78 1.13-.83 1.14-.05v.05c.6.23 1.01.8 1.01 1.47 0 .75-1.14.75-1.14 0 0-.24-.2-.44-.44-.44-.43-.02-.62.64-.19.79M15.8 4.57l.75.24c1.37.44 1.32 2.44.02 2.9.03.81-1.18.81-1.15 0-.76-.18-1.62-1.86-.44-2.04.32 0 .57.26.57.57 0 .25.2.44.44.44.43.03.63-.63.2-.78l-.75-.25c-1.37-.43-1.33-2.44-.02-2.9-.03-.78 1.13-.82 1.14-.04v.04c.6.23 1.02.8 1.02 1.48 0 .74-1.15.74-1.15 0 0-.25-.2-.44-.44-.44-.42-.03-.63.63-.19.78M26.83 15.6l.74.24c1.37.42 1.33 2.44.02 2.9.04.78-1.12.83-1.14.05v-.05c-.6-.23-1.01-.8-1.01-1.47 0-.75 1.14-.75 1.14 0 0 .24.2.44.44.44.42.02.62-.64.19-.78l-.74-.25c-1.38-.43-1.34-2.45-.02-2.9-.04-.78 1.12-.83 1.14-.05v.05c.6.23 1 .8 1 1.47 0 .75-1.13.75-1.13 0 0-.24-.2-.44-.44-.44-.43-.02-.62.64-.2.79',
            },
          },
        ],
      },
    ],
  },
  name: 'affiliate',
  theme: 'filled',
};

export default AffiliateFilled;
