// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { BankOutlined as BankOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const BankOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={BankOutlinedSvg} />
);

BankOutlined.displayName = 'BankOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(BankOutlined);
