// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CasinoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2022_6939)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M29.94 2.17 17.87.04a2.5 2.5 0 0 0-2.89 2.02l-.11.65a4.4 4.4 0 0 1 3.68 3.2l.74 2.76a2.24 2.24 0 0 1 3.04 1.39 2.25 2.25 0 0 1 4 1.75c-.47 2.66-3.33 4.8-4.68 5.68l2.07 7.71q.15.63.14 1.24l1.74.3a2.5 2.5 0 0 0 2.9-2.02l3.46-19.66a2.5 2.5 0 0 0-2.02-2.9',
            },
          },
          {
            tag: 'path',
            attrs: {
              d: 'M16.74 6.4a2.5 2.5 0 0 0-3.05-1.77L1.85 7.81a2.5 2.5 0 0 0-1.76 3.05l5.16 19.29a2.5 2.5 0 0 0 3.06 1.76l11.84-3.17a2.5 2.5 0 0 0 1.76-3.05zm-9.2 13.18a.7.7 0 0 1-.2-.7l1.81-5.36a.66.66 0 0 1 1.07-.29l4.24 3.74c.2.17.28.45.2.7l-1.82 5.36a.66.66 0 0 1-1.06.29z',
            },
          },
        ],
      },
    ],
  },
  name: 'casino',
  theme: 'filled',
};

export default CasinoFilled;
