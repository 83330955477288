// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PersonFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M15.91 8.03a4.03 4.03 0 1 1-8.06 0 4.03 4.03 0 0 1 8.06 0M4.76 16.55a3.6 3.6 0 0 1 3.59-3.6h7.12a3.6 3.6 0 0 1 0 7.19H8.35a3.6 3.6 0 0 1-3.6-3.6',
        },
      },
    ],
  },
  name: 'person',
  theme: 'filled',
};

export default PersonFilled;
