// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SportFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M27.31 4.69A16 16 0 0 0 16 0 16 16 0 0 0 4.69 4.69 16 16 0 0 0 0 16c0 4.27 1.66 8.3 4.69 11.31A16 16 0 0 0 16 32c4.27 0 8.3-1.66 11.31-4.69A16 16 0 0 0 32 16c0-4.27-1.66-8.3-4.69-11.31m-10.37.74 3.78-2.74a14.2 14.2 0 0 1 6.91 5.3l-1.36 4.22-4.83 1.57-4.5-3.28zM11.28 2.7l3.78 2.74v5.07l-4.5 3.28-4.83-1.57L4.37 8a14.2 14.2 0 0 1 6.91-5.31m-6.2 22.27a14 14 0 0 1-3.2-8.59L5.16 14l4.82 1.57 1.72 5.3-2.98 4.1zm15.63 4.36a14 14 0 0 1-9.42 0l-1.05-3.26 2.97-4.1h5.58l2.98 4.1zm6.2-4.36h-3.63l-2.98-4.1 1.72-5.3 4.82-1.57 3.28 2.38a14 14 0 0 1-3.2 8.59',
        },
      },
    ],
  },
  name: 'sport',
  theme: 'filled',
};

export default SportFilled;
